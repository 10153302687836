<template>
  <div class="showBoard flex justify-content-end flex-column w-100 h-100">
    <div class="boardHeader">
      <h2 class="courseName">{{ course.title }}</h2>
      <p class="grade">
        {{ $t("g.grade") }} : <span class="pr-1">{{ this.grade }}</span>
      </p>
    </div>

    <b-container fluid class="listsContainer">
      <b-row class="flex-row flex-nowrap">
        <ListPosts
          v-for="list in lists"
          :key="list.id"
          :list="list"
          @showReport="showReport"
        />
        <!-- <report-component
          :reportable-id="reportable_id"
          :reportable-type="reportable_type"
        /> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListPosts from "@/components/classrooms/boards/posts/listPosts/ListPosts.vue";
import ReportComponent from "@/components/shared/ReportComponent/index.vue";
import { BContainer, BRow } from "bootstrap-vue";

export default {
  name: "ShowBoard",
  components: {
    ReportComponent,
    ListPosts,
    BContainer,
    BRow,
  },
  data() {
    return {
      grade: "",
      course: {},
      lists: [],
      reportable_id: null,
      reportable_type: null,
    };
  },
  created() {
    this.getLists();
    this.getGrade();
  },
  methods: {
    getLists() {
      this.$http
        .get(`/guardian/boards/${this.$route.params.id}`)
        .then((response) => {
          this.course = response.data.data;
          this.lists = response.data.data.lists;
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGrade() {
      this.$http
        .get(
          `/guardian/student/${
            JSON.parse(localStorage.getItem("userData")).type_id
          }`
        )
        .then((response) => {
          this.grade = response.data.data.eduyear.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showReport(report) {
      this.reportable_id = report.reportable_id;
      this.reportable_type = report.reportable_type;
      this.$bvModal.show("bv-modal-report");
    },
  },
};
</script>

<style lang="scss" src="./_showBoard.scss"></style>
