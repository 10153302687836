<template>
  <b-col class="list_post-col" lg="3" md="5" sm="6" xs="12">
    <div class="header">
      <div class="list flex justify-content-center">
        <span class="list-title">{{ list.title }}</span>
      </div>
    </div>

    <div class="list-post">
      <div class="post" v-for="post in posts" :key="post.id">
        <div class="header">
          <span
            class="title p-0 m-0 cursor-pointer"
            @click="showPost(post.id)"
            >{{ userFullName(post) }}</span
          >
        </div>
        <div class="post-content cursor-pointer" @click="showPost(post.id)">
          <h6 class="d-flex justify-content-between">
            <span>{{ post.title }}</span>
          </h6>
          <img
            v-if="post.media && post.media.length > 0"
            :src="post.media[0].path"
            :alt="post.title"
            class="img-fluid h-100 w-100 bg-cover rounded-lg"
          />
          <span v-else>{{ post.text }}</span>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BFormTextarea } from "bootstrap-vue/src/components/form-textarea";
import { required } from "@core/utils/validations/validations";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ListPosts",
  components: {
    BDropdownItem,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      data: {
        board_id: "",
        list_id: "",
        post_id: "",
      },
      form_data: {
        title: "",
        text: "",
        media: null,
      },
      posts: [],
      okTitleHtml: "",
      report: {
        show: false,
        reportable_id: null,
        reportable_type: null,
      },
    };
  },
  created() {
    this.data.board_id = this.$route.params.id;
    this.data.list_id = this.list.id;
    this.getPots();
  },
  methods: {
    getPots() {
      this.$http
        .get(
          `/guardian/boards/${this.$route.params.id}/lists/${this.list.id}/posts`
        )
        .then((response) => {
          this.posts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.addPost();
    },

    userFullName(post) {
      let fullName = "";
      if (post.user) {
        if (post.user.first_name) {
          fullName += post.user.first_name;
        }
        if (post.user.middle_name) {
          fullName += ` ${post.user.middle_name}`;
        }
        if (post.user.last_name) {
          fullName += ` ${post.user.last_name}`;
        }
      } else {
        fullName = post.title;
      }
      return fullName;
    },
    showPost(postId) {
      this.$router.push({
        name: "showPost",
        params: {
          boardId: this.$route.params.id,
          listId: this.list.id,
          postId: postId,
        },
      });
    },
    addReport(id) {
      this.report.reportable_id = id;
      this.report.reportable_type = "post";
      this.$emit("showReport", this.report);
    },
  },
};
</script>

<style lang="scss" src="./_listPosts.scss" />
